/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    shuffle: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M0 3.5A.5.5 0 01.5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.6 9.6 0 007.556 8a9.6 9.6 0 001.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.6 10.6 0 017 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 010-1H1c1.798 0 3.173-1.01 4.126-2.082A9.6 9.6 0 006.444 8a9.6 9.6 0 00-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 01-.5-.5"/><path pid="1" d="M13 5.466V1.534a.25.25 0 01.41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 01-.41-.192m0 9v-3.932a.25.25 0 01.41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 01-.41-.192"/>',
    },
});
